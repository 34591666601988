import { getCurrentUserApi, loginApi, verifySSOKeyApi } from 'api/auth';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { StoreContext } from './useStore';

export const useAuth = () => {
  const { dispatch, state } = useContext(StoreContext);
  const queryClient = useQueryClient();

  const setUser = useCallback((data) => {
    dispatch({
      type: 'setUser',
      payload: data,
    });
  }, []);

  const logout = useCallback(() => {
    dispatch({
      type: 'logout',
    });
    window.location = '/';
  }, []);

  const loginMutation = useMutation((payload) => loginApi(payload), {
    onSuccess: (res) => {
      dispatch({
        type: 'loginSuccess',
        payload: res,
      });
    },
    onError: () => {
      dispatch({
        type: 'loginFail',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries('auth/login');
    },
  });

  const loginSSOMutation = useMutation((payload) => verifySSOKeyApi(payload), {
    onSuccess: (res) => {
      dispatch({
        type: 'loginSuccess',
        payload: res,
      });
    },
    onError: () => {
      dispatch({
        type: 'loginFail',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries('auth/login');
    },
  });
  const login = async (data) => {
    try {
      dispatch({
        type: 'login',
      });
      await loginMutation.mutateAsync(data);
    } catch (error) {
      //
    }
  };

  const loginSSO = async (data) => {
    try {
      dispatch({
        type: 'login',
      });
      await loginSSOMutation.mutateAsync(data);
    } catch (error) {
      //
    }
  };

  // useEffect(() => {
  //   if (state.isAuthenticated) {
  //     getCurrentUserApi().then((res) => {
  //       setUser(res);
  //     });
  //   }
  // }, [state.isAuthenticated]);

  return {
    user: state.user,
    login,
    loginSSO,
    logout,
    setUser,
    isAuthenticated: state.isAuthenticated,
    loading: state.loading,
    loginError: loginMutation.error,
  };
};

export const useHasRole = (roles) => {
  const { state } = useContext(StoreContext);
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    setHasRole(!!roles?.find((e) => e === state.user?.role));
  }, [state.user]);

  return hasRole || !roles;
};
