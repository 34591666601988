import { useHasRole, useAuth } from 'hooks/useAuth';
import PrivateLayoutPage from 'layouts/PrivateLayout';
import { flatMap, groupBy, map } from 'lodash';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from 'utils/loadable';

const routes = [

  {
    path: '/userProducts',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/userProducts/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/userProducts/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/userProducts/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/userProducts/Show')),
      },
    ],
  },

  {
    path: '/products',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/products/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/products/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/products/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/products/Show')),
      },
    ],
  },

  {
    path: '/userIds',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/userIds/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/userIds/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/userIds/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/userIds/Show')),
      },
    ],
  },

  {
    path: '/tags',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/tags/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/tags/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/tags/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/tags/Show')),
      },
    ],
  },

  {
    path: '/vendors',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/vendors/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/vendors/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/vendors/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/vendors/Show')),
      },
    ],
  },

  {
    path: '/designs',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/designs/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/designs/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/designs/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/designs/Show')),
      },
    ],
  },

  {
    path: '/users',
    routes: [
      {
        path: '/',
        component: loadable(import('pages/users/List')),
      },
      {
        path: '/create',
        component: loadable(import('pages/users/Create')),
      },
      {
        path: '/:id/edit',
        component: loadable(import('pages/users/Edit')),
      },
      {
        path: '/:id',
        component: loadable(import('pages/users/Show')),
      },
    ],
  },
  {
    path: '/',
    component: loadable(import('pages/Home')),
    exact: true,
    title: 'dashboard.title',
  },
];

const groupRoutes = groupBy(
  flatMap(routes, (route) => {
    if (route.routes) {
      return map(route.routes, (subRoute) => ({
        ...subRoute,
        path: route.path + subRoute.path,
        exact: subRoute.path === '/',
        component: subRoute.component || route.component,
      }));
    }
    return route;
  }),
  'hasPrivateLayoutWrapper',
);

const wrappedRoutes = map(groupRoutes.undefined, (route) => (
  <PrivateRoute {...route} key={route.path} />
));

function PrivateRoute({ title, roles, component: Component, ...rest }) {
  const hasRole = useHasRole(roles);
  const { isAuthenticated } = useAuth();

  return hasRole ? (
    <Route
      {...rest}
      render={
        (props) =>
          isAuthenticated ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                // eslint-disable-next-line
                state: { from: props.location },
              }}
            />
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  ) : (
    <Route render={null} />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  title: PropTypes.string,
  hasPrivateLayoutWrapper: PropTypes.bool,
  roles: PropTypes.array,
};

const PrivateRoutes = () => (
  <PrivateLayoutPage>
    <Switch>{wrappedRoutes}</Switch>
  </PrivateLayoutPage>
);

export const privateRouteWithoutLayout = () =>
  groupRoutes.false?.map((e) => <Route {...e} key={e.path} />);

export default PrivateRoutes;
