import {
  DashboardFilled,
  PictureOutlined,
  ShopOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';
import ProLayout from '@ant-design/pro-layout';
import { Alert } from 'antd';
import { getConfigApi } from 'api/configs';
import { appConfig } from 'configs/config';
import { useStore } from 'hooks/useStore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import MenuHeader from './MenuHeader';
import MenuSidebarItem from './MenuSidebarItem';
import RightContentHeader from './RightContentHeader';

const PrivateLayout = ({ children }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const ROUTES = {
    routes: [
      {
        path: '/',
        name: 'Dashboard',
        icon: <DashboardFilled />,
        key: '',
      },
      {
        path: '/tags',
        name: t('tags.header'),
        icon: <TagOutlined />,
        key: 'tags',
      },
      {
        path: '/vendors',
        name: t('vendors.header'),
        icon: <ShopOutlined />,
        key: 'vendors',
      },
      {
        path: '/users',
        name: t('users.header'),
        icon: <UserOutlined />,
        key: 'users',
      },
      {
        path: '/products',
        name: t('products.header'),
        icon: <PictureOutlined />,
        key: 'products',
      },
      {
        path: '/designs',
        name: t('designs.header'),
        icon: <PictureOutlined />,
        key: 'designs',
      },
    ],
  };

  const [settings] = useState({
    colorWeak: false,
    title: appConfig.title,
    headerHeight: 60,
    fixedHeader: true,
    fixSiderbar: true,
    language: 'en-US',
  });
  const [, dispatch] = useStore();
  useQuery(
    'config',
    async () => {
      const configs = await getConfigApi();
      dispatch({ type: 'setConfigs', configs });
    },
    [],
  );

  useEffect(() => {}, []);

  return (
    <div className="h-screen">
      <ProLayout
        route={ROUTES}
        logo={appConfig.logo}
        location={{
          pathname,
        }}
        menuHeaderRender={MenuHeader}
        menuItemRender={MenuSidebarItem}
        rightContentRender={() => <RightContentHeader />}
        {...settings}
        language="en-US"
      >
        <Alert.ErrorBoundary>{children}</Alert.ErrorBoundary>
      </ProLayout>
      {/* <SettingDrawer
        pathname={pathname}
        settings={settings}
        onSettingChange={setSetting}
        disableUrlParams
        hideHintAlert
        hideCopyButton
        language="en-US"
      /> */}
    </div>
  );
};

PrivateLayout.propTypes = {
  logout: PropTypes.func,
  userInfo: PropTypes.object,
};

export default PrivateLayout;
