import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
.ant-pro-sider {
  background:linear-gradient(180deg, #051E44 0%, #40075B 100%);
}
.ant-layout-header {
  background-color: transparent;
}

.ant-table-column-title {
  text-transform: capitalize;
}

.ant-card {
  border-radius: 15px;
}

.ant-pro-global-header {
  background-color: rgba(255,255,255,0.5);
  margin: 10px 20px;
  border-radius: 30px;
  box-shadow:0 1px 20px 10px rgb(0 21 41 / 8%);
}
.ant-page-header {
  padding: 16px 0px;
}
.ant-list {
  .ant-col {
    height: 100%;
    .ant-list-item {
      height: 100%;
    }
    .ant-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      .ant-card-body{
        flex:1;
      }
    }
  }
}
.ant-tabs-card {
  .ant-tabs-tab-active {
    background-color: ${({ theme }) => theme.palette.primary} !important;
    .ant-tabs-tab-btn {
      color: white !important;
    }
  }
}
.ant-form-item-label {
  font-weight: bold;
  text-transform: capitalize;
}
.ant-modal {
  top: 20px;
  max-height: calc(100vh - 40px);
  .ant-modal-body {
    max-height: calc(100vh - 150px);
    overflow-y:auto;
  }
}
.ant-btn {
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
}
.ant-menu-item-selected {
    width: calc(100% - 10px) !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    padding: 0 11px !important;
    background-color: #ffffff20 !important;
    border-radius: 20px;
  }

.ant-anchor-wrapper {
  overflow:visible;
}
.ant-anchor-ink {
  &::before {
    display:none;
  }
}
.ant-modal-content {
  border-radius:20px;
  opacity: 0.9;
  .ant-modal-header {
    border-radius: 20px 20px 0px 0px;
  }
}
  .ant-btn {
    path {
      fill: inherit;
    }
  }
  .ant-btn-dangerous {
    path {
      fill: red;
    }
  }
  .ant-btn-dangerous {
    &.ant-btn-primary {
      path {
      fill: white;
    }
    }
  }
  .btn-green {
    background: #1acdb3;
    border-color: #1acdb3;
    color: white;
    path {
      fill:white;
    }
  }
  .btn-orange {
    background: #d16a1e;
    border-color: #d16a1e;
    color:white;
    path {
      fill:white;
    }
  }

  .btn-blue {
    background:#1E76B3 ;
    border-color: #1E76B3;
    color:white;
    path {
      fill:white;
    }
  }
`;
