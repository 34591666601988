import { createGlobalStyle } from 'styled-components';
import { CLASS_UTILITY } from 'utils/css';

export default createGlobalStyle`
${CLASS_UTILITY}
.ant-design-pro {
  min-height: 100vh;
}
.ant-card-actions {
  li {
    margin:0px;
    button {
      width: 100%;
      height:50px;
    }
  }
}
.bg {
  background: linear-gradient(180deg, #051E44 0%, #40075B 100%);
}
.h-full {
  height: 100%;
}
.text-red {
  color:${({ theme }) => theme.color.red};
}
.thumbnail {
  width: 100px;
  height: 100px;
  object-fit:contain;
}
`;
