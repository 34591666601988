import common from './common.json';
import userProducts from './userProducts.json';
import products from './products.json';
import userIds from './userIds.json';
import tags from './tags.json';
import vendors from './vendors.json';
import designs from './designs.json';
import users from './users.json';
import orders from './orders.json';
import userRequests from './userRequests.json';
import transactions from './transactions.json';
import paymentOrders from './paymentOrders.json';
import bankAccounts from './bank-accounts.json';
import designers from './designers.json';
import customers from './customers.json';

export default {
  ...userProducts,
  ...products,
  ...userIds,
  ...tags,
  ...vendors,
  ...designs,
  ...users,
  ...common,
  ...orders,
  ...userRequests,
  ...transactions,
  ...paymentOrders,
  ...bankAccounts,
  ...designers,
  ...customers,
  ...users,
};
